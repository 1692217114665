import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { APP_NAME } from '../utils/const';

const NotFound = () => (
  <Layout withHeader>
    <div className="container mt-32 flex flex-col items-center justify-center space-y-14">
      <h1 className="text-6xl">404: Page not found</h1>
      <a href="/" className="text-2xl font-bold text-blue-600 underline">
        Go back
      </a>
    </div>
  </Layout>
);

export default NotFound;

export const Head = () => <SEO title={`Page not found - ${APP_NAME}`} />;
